import AdminLayout from '@/components/Layouts/Admin/DefaultLayout';
import { Navigate, useRoutes } from 'react-router-dom';
import { RoleProtector } from '@/core/protector';
import Error404 from '@/app/errors/4004Error';
import { AuthLayout } from '@/components/Layouts/Admin/AuthLayout';
import { Signup } from '@/app/auth/signup';
import { Login } from '@/app/auth/login';
import Widgets from '@/app/dashboard/widgets';
import { Docs } from '@/app/dashboard/docs';
import Users from '@/app/dashboard/users';
import Transactions from '@/app/dashboard/categories/components';
import Transfers from '@/app/dashboard/tranfers/components';
import Setting from '@/components/Layouts/Admin/Setting';
import ProfileSettings from '@/app/dashboard/Profile';
import MainDashboard from '@/app/dashboard/main';


export default function AppRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/login" replace /> },
        {
            element: <AuthLayout />,
            children: [
                { path: 'login', element: <Login /> },
                { path: 'signup', element: <Signup /> },

            ],
        },

        { path: '*', element: <Error404 /> },
        {
            path: '/dashboard',
            element:<AdminLayout />,
            children: [
                { index: true, element: <MainDashboard /> },
                { path: 'dashboard', element: <Widgets /> },
                { path: 'documentation', element: <Docs /> },
                { path: 'users', element: <Users /> },
                { path: 'transfers', element: <Transfers /> },
                { path: 'transactions', element: <Transactions /> },
                { path: 'profile', element: <ProfileSettings /> },

            ],
        }
    ]);
}
