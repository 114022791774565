import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import { useGetUsersQuery } from '@/app/auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';
import { UserModel } from '@/app/auth/redux/user';
import { useGetApiKeyQuery } from '../../redux.Api';
import { ApiKeyModel } from '../../types.Api/apiModel';
import ExportDataTable2 from '@/components/datatable/Datatable2';
import AddAPI from './API/ApiDialog';

const ApiKey = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const dispatch = useDispatch();

    const { isLoading, refetch, isFetching, data }: any = useGetApiKeyQuery(
        null,
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    const { users } = useAppSelector((state: any) => state.usersState);
    const sortedData = users?.list || [];

    useEffect(() => {
        setIsLoadingData(isFetching);
    }, [isFetching]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<ApiKeyModel>[] = [
        {
            title: 'Access Key',
            accessor: 'access_key',
            render: row => <p className="capitalize">{row?.access_key}</p>,
        },
        {
            title: 'Environment',
            accessor: 'environment',
            render: row =>
                row?.environment === 'dev' ? (
                    <p className="badge badge-success">
                        <span className="mr-1  badge  bg-green-500  px-2 py-1 text-white">
                            Development
                        </span>
                    </p>
                ) : (
                    <p className="badge badge-primary">
                        <span className="mr-1  badge  bg-primary px-4 py-1 text-white">
                            Production
                        </span>
                    </p>
                ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
    ];

    useEffect(() => {
        dispatch(setPageTitle('Users'));
    }, [dispatch]);

    return (
        <div className="">
            <div className='flex flex-row justify-end'>
                <AddAPI handleRefetch={handleRefetch} />
            </div>

            <ExportDataTable2
                columns={columns}
                data={data ?? []}
                total={1}
                tableName="Api Keys"
                currentPage={1}
                nextPage={0}
                previousPage={0}
                lastPage={0}
                isLoading={isLoading}
                filterComponent={undefined}
            />
        </div>
    );
};

export default ApiKey;
