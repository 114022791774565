import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import {
  useGetUsersQuery,
} from '@/app/auth/redux';
import ExportDataTable, {
  TableColumn,
} from '@/components/datatable';
import formatDateToLongForm, { formatDateToLongFormWithTime } from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';

import { UserModel } from '@/app/auth/redux/user';
import { TransactionModel } from '../api/model';
import { capitalize } from '@mui/material';
import { useGetTransactionQuery } from '../api';


const Transactions = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [from, setStartDateFilter] = useState('');
  const [to, setEndDateFilter] = useState('');
  const [role, setRole] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search') || '';
  const pageNumber = Number(queryParams.get('pageNumber')) || 1;
  const pageSize = Number(queryParams.get('pageSize')) || 10;
  const dispatch = useDispatch();

  const {data,isLoading, refetch, isFetching }:any = useGetTransactionQuery(
    {
      pageNumber,
      pageSize,
      from,
      to,
      search,

    },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const handleRefetch = async () => {
    try {
      setIsLoadingData(true);
      await refetch();
    } catch (error) {

    } finally {
      setIsLoadingData(false);
    }
  };
;

  const resetFilters = () => {
    setRole('');
    setStartDateFilter('');
    setEndDateFilter('');
  };

  useEffect(() => {
    setIsLoadingData(isFetching);
  }, [isFetching]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {

      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, search, role, refetch]);

  const columns: TableColumn<TransactionModel>[] = [
      {
          title: 'External Id',
          accessor: 'presentationReferenceId',
          render: (row) => (
              <p>{row.presentationReferenceId}</p>
          ),
      },
    {
      title: 'Names',
      accessor: 'user.name',
      render: (row) => <p className="capitalize">{row?.user?.name} </p>,
    },
    {
      title: 'Description',
      accessor: 'description',
      render: (row) => <p>{capitalize(row. description)} </p>,
    },
    {
      title: 'Tel company  ',
      accessor: 'telco',
      render: (row) => (
        <p>{row?.telco}</p>
      ),
    },
    {
        title: 'Amount',
        accessor: 'amount',
        render: (row) => (
          <p>{row?.amount}</p>
        ),
      },
      {
        title: 'Phone Number  ',
        accessor: 'phoneNumber',
        render: (row) => (
          <p>{row?.phoneNumber}</p>
        ),
      },
      {
        title: 'Status',
        accessor: 'status',
        render: row => {
            let badgeColor;
            let badgeText;
            switch (row?.status) {

                case 'pending':
                    badgeColor = 'bg-warning';
                    badgeText = 'Pending';
                    break;
                case 'failed':
                    badgeColor = ' bg-danger ';
                    badgeText = 'Failed';
                    break;
                case 'success':
                    badgeColor = ' bg-success';
                    badgeText = 'success';
                    break;

                default:
                    badgeColor = 'bg-gray-500';
                    badgeText = 'Unknown';
            }
            return (
                <div className={`badge ${badgeColor}  text-center text-white rounded`}>
                    {badgeText}
                </div>
            );
        },
    },


      {
        title: 'Date   ',
        accessor: 'created_at',
        render: (row) => (
          <p>{formatDateToLongFormWithTime(row?.created_at)}</p>
        ),
      },


  ];

  useEffect(() => {
    dispatch(setPageTitle('Transactions |'));
  }, [dispatch]);

  return (
    <div className="p-3">
      <div className="flex flex-row justify-between items-center my-2">
        <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
          <li>
            <Link
              to="/dashboard"
              className="text-primary hover:underline"
            >
              Dashboard
            </Link>
          </li>
          <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
            <span>Transactions</span>
          </li>
        </ul>

      </div>
      <ExportDataTable
        columns={columns}
        data={data?.list ?? []}
        total={data?.total ?? 0}
        tableName="Transactions"
        currentPage={data?.currentPage ?? 0}
        nextPage={data?.nextPage ?? 0}
        previousPage={data?.previousPage ?? 0}
        lastPage={data?.lastPage ?? 0}
        isLoading={isLoading}
        filterComponent={
          <Filter
            resetFilters={resetFilters}
            title="Filter users"
            children={
              <>
                <div className="flex flex-col  ">
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-input"
                      onChange={(e) =>
                        setStartDateFilter(e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-input "
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                </div>
              </>
            }
          />
        }
      />
    </div>
  );
};

export default Transactions;
