
import toast from "react-hot-toast";
import { ResponseModel } from "@/core/types";
import baseApi from "@/core/lib/base";
import { IpModel } from "../Types.Ip/ipModel";
import { ipSlice } from "../Types.Ip/ipSlice";
import { storage } from "@/utils";

export const IPaddress = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getIpAddresses: builder.query<ResponseModel<IpModel[]>, null>({
      query: () => ({
        method: "GET",
        url: "/api/v1/ip-addresses",
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;

          dispatch(ipSlice.actions.setIpAddresses(data));
        } catch (error: any) {
            if(error.error.status === 401) {
                storage.removeToken();
                window.location.href = '/login';
            }
          throw error;
        }
      },
    }),
    createIpAddress: builder.mutation<IpModel, Partial<IpModel>>({
      query: (newIp) => ({
        method: "POST",
        url: "/api/v1/ip-addresses", // Change the endpoint URL
        body: newIp,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;


          toast.success('IP Address Created');

        } catch (error:any) {

          toast.error(error?.error?.data?.message)
          throw error;
        }
      },
    }),
    editIpAddress: builder.mutation<IpModel, { id: string; data: Partial<IpModel> }>({
        query: ({ id, data }) => ({
          method: "PUT",
          url: `/api/v1/ip-addresses/${id}`, // Change the endpoint URL
          body: data,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;


            toast.success('IP Address Edited');
          } catch (error:any) {

            toast.error(error?.error?.data?.message)
            throw error;
          }
        },
      }),

      deleteIpAddress: builder.mutation<void, string>({
        query: (id) => ({
          method: "DELETE",
          url: `/api/v1/ip-addresses/${id}`, // Change the endpoint URL
        }),
        onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;


            toast.success('IP Address Deleted');

          } catch (error:any) {
            toast.error(error?.error?.data?.message);
            throw error;
          }
        },
      }),

  }),
});

export const { useGetIpAddressesQuery, useCreateIpAddressMutation,useEditIpAddressMutation, useDeleteIpAddressMutation} = IPaddress;
