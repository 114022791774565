import React, { Fragment, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useCreateApiKeyMutation } from '../../../redux.Api';
import { Dialog, Transition } from '@headlessui/react';

export default function AddAPI({ handleRefetch }: any) {
    const [modal1, setModal1] = useState(false);
    const [ipType, setIpType] = useState<string>('prod');
    const [createApiKey, { isLoading }] = useCreateApiKeyMutation();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const newApiKeyData: any = {
                environment: ipType,
            };
            const data: any = await createApiKey(newApiKeyData);
            handleRefetch([data.data]);
            setModal1(false);
        } catch (error) {}
    };
    return (
        <>
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary "
                >
                    Create ApiKey
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Create Api Key
                                        </div>
                                    </div>
                                    <div className="p-5 ">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <select
                                                    name="ipType"
                                                    value={ipType}
                                                    onChange={e =>
                                                        setIpType(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="form-select text-gray-500"
                                                >
                                                    <option value="prod">
                                                        Prod
                                                    </option>
                                                    <option value="dev">
                                                        Dev
                                                    </option>
                                                </select>
                                            </div>

                                            <div className="mt-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary "
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                color: 'black',
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>Save</span>
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
