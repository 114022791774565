import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import {
  useGetUsersQuery,
} from '@/app/auth/redux';
import ExportDataTable, {
  TableColumn,
} from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';
import { AddUser } from './AddUser';
import { UserModel } from '@/app/auth/redux/user';


const Users = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [from, setStartDateFilter] = useState('');
  const [to, setEndDateFilter] = useState('');
  const [role, setRole] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search') || '';
  const pageNumber = Number(queryParams.get('pageNumber')) || 1;
  const pageSize = Number(queryParams.get('pageSize')) || 10;
  const dispatch = useDispatch();

  const { isLoading, refetch, isFetching } = useGetUsersQuery(
    {
      pageNumber,
      pageSize,
      from,
      to,
      search,
      role,
    },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const handleRefetch = async () => {
    try {
      setIsLoadingData(true);
      await refetch();
    } catch (error) {

    } finally {
      setIsLoadingData(false);
    }
  };

  const { users } = useAppSelector((state: any) => state.usersState);
  const sortedData = users?.list || [];


  const resetFilters = () => {
    setRole('');
    setStartDateFilter('');
    setEndDateFilter('');
  };

  useEffect(() => {
    setIsLoadingData(isFetching);
  }, [isFetching]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {

      } finally {
        setIsLoadingData(false);
      }
    };


    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, search, role, refetch]);


  const columns: TableColumn<UserModel>[] = [
    {
      title: 'Names',
      accessor: 'name',
      render: (row) => <p className="capitalize">{row.name}</p>,
    },
    {
      title: 'Username',
      accessor: 'username',
      render: (row) => <p>{row.username} </p>,
    },
    {
      title: 'Percentage cut',
      accessor: 'percentage_cut',
      render: (row) => <p>{row.percentage_cut} </p>,
    },

    {
      title: 'Role',
      accessor: 'role',
      render: (row) => <p>{row.role}</p>,
    },
    {
      title: 'Date Created  ',
      accessor: 'created_at',
      render: (row) => (
        <p>{formatDateToLongForm(row?.created_at)}</p>
      ),
    },


  ];

  useEffect(() => {
    dispatch(setPageTitle('Users |'));
  }, [dispatch]);

  return (
    <div className="p-3">
      <div className="flex flex-row justify-between items-center my-2">
        <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
          <li>
            <Link
              to="/admin"
              className="text-primary hover:underline"
            >
              Dashboard
            </Link>
          </li>
          <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
            <span>Users</span>
          </li>
        </ul>
        <AddUser   handleRefetch = {handleRefetch}/>
      </div>
      <ExportDataTable
        columns={columns}
        data={sortedData ?? []}
        total={users?.total ?? 0}
        tableName="Users"
        currentPage={users?.currentPage ?? 0}
        nextPage={users?.nextPage ?? 0}
        previousPage={users?.previousPage ?? 0}
        lastPage={users?.lastPage ?? 0}
        isLoading={isLoading}
        filterComponent={
          <Filter
            resetFilters={resetFilters}
            title="Filter users"
            children={
              <>
                <div className="flex flex-col  ">
                  <div className="mb-4">
                    <label htmlFor="fromDate">Filter role :</label>
                    <select
                      className="form-select"
                      name="role"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">Select role</option>
                      <option value="admin">Admin</option>
                      <option value="developer">Developer</option>
                      </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-input"
                      onChange={(e) =>
                        setStartDateFilter(e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-input "
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                </div>
              </>
            }
          />
        }
      />
    </div>
  );
};

export default Users;
