
import ReactApexChart from 'react-apexcharts';
import { IRootState } from '@/store';
import { useSelector } from 'react-redux';
import { MonthTransactionModel, WeekTransactionModel, YearTransactionModel } from '@/app/dashboard/main/api';
import React, { SetStateAction, useState } from 'react';

interface TimeChartProps {
    monthData: MonthTransactionModel[],
    yearData: YearTransactionModel[],
    weekData: WeekTransactionModel[],
    setYear: React.Dispatch<SetStateAction<string>>,
    year: string
}

export default function TimeChart({ monthData, yearData, weekData, setYear, year } : TimeChartProps) {

    const [type, setType] = useState<'wk' | 'mn' | 'yr'>('wk');

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setType(event.target.value as 'wk' | 'mn' | 'yr');
    };

    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

    const lineChart: any = {
        series: [
            {
                name: 'Transactions',
                data: type === 'wk' ?
                    weekData.map((item) => item.total) : type === 'mn' ?
                        monthData.map((item) => item.total) : yearData.map((item) => item.total),
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'line',
                toolbar: false,
            },
            colors: ['#4361EE'],
            tooltip: {
                marker: false,
                // y: {
                //     formatter(number: number) {
                //         return '' + number;
                //     },
                // },
            },
            stroke: {
                width: 3,
                curve: 'smooth',
            },
            xaxis: {
                categories: type === 'wk' ?
                    weekData.map((item) => item.day) : type === 'mn' ?
                        monthData.map((item) => item.month) : yearData.map((item) => item.year),
                axisBorder: {
                    color: themeConfig.theme === 'light' ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: false,
                labels: {
                    offsetX: 0,
                },
            },
            grid: {
                borderColor: themeConfig.theme === 'light' ? '#191e3a' : '#e0e6ed',
            },
        },
    };

    return (
        <div className="rounded-md bg-white dark:bg-black py-4 px-3">
            <div className="py-4">
                Transaction Reports
            </div>
            <div className="w-full flex flex-row justify-between">
                <div className="w-28">
                    <select
                        value={type}
                        onChange={handleChange}
                        className="border rounded-md p-2 border-gray-300 dark:border-gray-50/20">
                        <option value="wk">This Week</option>
                        <option value="mn">Monthly</option>
                        <option value="yr">Yearly</option>
                    </select>
                </div>

                {type === 'mn' ?
                    <div className="w-24">
                        <select
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            className="border rounded-md p-2 border-gray-300 dark:border-gray-50/20">
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>

                        </select>
                    </div> : null}
            </div>
            <ReactApexChart series={lineChart.series} options={lineChart.options}
                            className="rounded-lg bg-white mt-4 dark:bg-black overflow-hidden" type="line"
                            height={300} />
        </div>
    )
}
