import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import { useGetUsersQuery } from '@/app/auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';

import { UserModel } from '@/app/auth/redux/user';
import { useGetIpAddressesQuery } from '../redux.Ip';
import { IpModel } from '../Types.Ip/ipModel';
import EditIPaddress from './ip/editIPdialog/EditIP';
import DeleteIP from './ip/DeleteIp/DeleteIp';
import AddIPaddress from './ip/addIPdialog/AddIPdialog';
import ExportDataTable2 from '@/components/datatable/Datatable2';

const IPaddress = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
  
    const queryParams = new URLSearchParams(window.location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const dispatch = useDispatch();

    const { isLoading, refetch, isFetching,data }:any = useGetIpAddressesQuery(
    null,
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

  
   

    useEffect(() => {
        setIsLoadingData(isFetching);
    }, [isFetching]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize,search,refetch]);

    const columns: TableColumn<IpModel>[] = [
        {
            title: 'Names',
            accessor: 'name',
            render: row => <p className="capitalize">{row.name}</p>,
        },
        {
            title: 'IP',
            accessor: 'ip',
            render: row => <p className="capitalize">{row.ip}</p>,
        },

        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <EditIPaddress data={row} handleRefetch={handleRefetch} />

                    <DeleteIP data={row} handleRefetch={handleRefetch} />
                </div>
            ),
        },
    ];



    return (
        <div className="">
         <div className='flex flex-row justify-end'>
         <AddIPaddress handleRefetch={handleRefetch} />
         </div>
            <ExportDataTable2
                columns={columns}
                data={data ?? []}
                total={1}
                tableName="Users"
                currentPage={1}
                nextPage={0}
                previousPage={0}
                lastPage={0}
                isLoading={isLoading}
                filterComponent={
                   undefined
                }
            />
        </div>
    );
};

export default IPaddress;
