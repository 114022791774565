import { PencilIcon } from '@heroicons/react/24/outline';
import { CircularProgress } from '@mui/material';
import React, { Fragment, useRef, useState } from 'react';
import { useEditIpAddressMutation } from '../../../redux.Ip';
import { Dialog, Transition } from '@headlessui/react';
import IconEdit from '@/components/Icon/IconEdit';
import IconPencil from '@/components/Icon/IconPencil';

export default function EditIPaddress({ data, handleRefetch }: any) {
    const [modal1, setModal1] = useState(false);
    const [name, setName] = useState(data?.name);
    const [ip, setIp] = useState(data?.ip);
    const [editIpAddress, { isLoading }] = useEditIpAddressMutation();

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response: any = await editIpAddress({
                id: data.id,
                data: {
                    name: name,
                    ip: ip,
                },
            });
            setModal1(false)
            handleRefetch();

        } catch (error) {
            return error;
        }
    };

    return (
        <>
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="  btn btn-sm btn-warning text-white"
                >
                 <IconPencil/>
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Edit Ip Address
                                        </div>
                                    </div>
                                    <div className="p-5 ">
                                        <form onSubmit={handleSave}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder={data?.name}
                                                    className="form-input"
                                                    value={name}
                                                    onChange={e =>
                                                        setName(e.target.value)
                                                    }
                                                />
                                            </div>

                                            <div className="form-group flex flex-row mt-2 ">
                                                <input
                                                    type="text"
                                                    placeholder={data?.ip}
                                                    className="form-input"
                                                    value={ip}
                                                    onChange={e =>
                                                        setIp(e.target.value)
                                                    }
                                                />
                                            </div>

                                            <div className="mt-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                color: 'black',
                                                            }}
                                                        />
                                                    ) : (
                                                        <span>save</span>
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
