import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
export const Docs = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Documentation |'));
    });

    const [copied, setCopied] = useState(false);
    const scrollToSection = (id: any) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleCopy = () => {
        setCopied(true);
        // Reset the copied state after 2 seconds
        setTimeout(() => setCopied(false), 2000);
    };
    return (
        <div className="flex flex-col md:flex-row justify-end py-4">
        <div className="md:w-1/6 py-6 px-4">
            <h3 className="mb-4 text-2xl font-semibold">Initiate Payment</h3>
            <ul className="space-y-2">
                    <li
                        className="cursor-pointer  text-primary "
                        onClick={() => scrollToSection('endpoint-section')}
                    >
                        Endpoint: /api/transfer
                    </li>
                    <li
                        className="cursor-pointer text-primary "
                        onClick={() => scrollToSection('request-section')}
                    >
                        Request
                    </li>
                    <li
                        className="cursor-pointer  text-primary  "
                        onClick={() => scrollToSection('response-section')}
                    >
                        Response
                    </li>
                </ul>
            </div>

            <div className="md:w-4/5 px-4 panel rounded-md">

                <div
                    id="endpoint-section"
                    className="rounded-md"
                >
                    <div
                        className="border-b italic border-white-light p-6 py-3 pt-6 text-[22px] font-semibold dark:border-dark dark:text-white">
                        /api/transfer
                    </div>
                    <div className="px-6 py-3 font-semibold text-white-dark">
                        <p>
                            This API allows you to initiate a transfer with the
                            specified parameters.
                        </p>
                    </div>
                </div>

                <div
                    id="request-section"
                    className="rounded-md p-4 text-md "
                >
                    <h1 className="pb-2 text-lg border-b font-semibold ">Request</h1>
                    <p className="mb-1 mt-2"><strong>Method</strong>: POST
                    </p>
                    <ul className="list-inside space-y-3">
                        <li className="mb-1 mt-2"><strong>Content-Type</strong>: Application/Json
                        </li>
                    </ul>
                    <p className="mt-2 mb-2">
                        <strong className="">
                            Body:
                        </strong>
                    </p>

                    <div
                        className="relative flex flex-col bg-gray-100 dark:bg-gray-800 overflow-x-auto rounded-md    form-input text-sm">
                        <pre className="overflow-x-auto bg-opacity-75 p-4">
                            <code>
                                {`{
  "phoneNumber": "string",
  "referenceId": "string",
  "amount": 0,
  "callbackUrl": "string",
  "description": "string",
  "transfers": [
    {
      "phoneNumber": "string",
      "percentage": 0,
      "message": "string"
    }
  ]
}`}
                            </code>
                        </pre>
                        <CopyToClipboard
                            text={`{
            "phoneNumber": "string",
            "referenceId": "string",
            "amount": 0,
            "callbackUrl": "string",
            "description": "string",
            "transfers": [
              {
                "phoneNumber": "string",
                "percentage": 0,
                "message": "string"
              }
            ]
          }`}
                        >
                            <button
                                className="absolute top-2 right-2 rounded-md bg-primary px-3 py-1 text-white"
                                onClick={handleCopy}
                            >
                                {copied ? (
                                    <FontAwesomeIcon icon={faCheck} /> // Show checkmark icon when copied
                                ) : (
                                    <FontAwesomeIcon icon={faCopy} /> // Show copy icon by default
                                )}
                            </button>
                        </CopyToClipboard>
                    </div>

                    <div className="w-full mt-2">
                        <div className="flex items-center justify-center mb-5">
                            <div className="border border-white-dark/20 rounded-md w-full p-5">
                                <ul className="space-y-3 list-inside text-md list-disc font-semibold">
                                    <li className="mb-1"><strong>PhoneNumber (string)</strong>: The phone number of the
                                        recipient.
                                    </li>
                                    <li className="mb-1"><strong>ReferenceId (string)</strong>: A unique reference ID
                                        for the transfer.
                                    </li>
                                    <li className="mb-1"><strong>Amount (number)</strong>: The amount to be transferred.
                                    </li>
                                    <li className="mb-1"><strong>CallbackUrl (string)</strong>: The URL to which the API
                                        will send a callback after the transfer.
                                    </li>
                                    <li className="mb-1"><strong>Description (string)</strong>: Additional information
                                        or description for the transfer.
                                    </li>
                                    <li className="mb-1"><strong>Transfers (array)</strong>: An array of transfer objects containing the following properties:
                                    </li>
                                    <li className="inline">
                                        <ul className="list-inside pl-10 space-y-3">
                                            <li className="mb-1 mt-2"><strong>PhoneNumber (string)</strong>: An array of
                                                transfer objects containing the following properties:
                                            </li>
                                            <li className="mb-1 mt-2"><strong>Percentage (number)</strong>: The
                                                percentage of the total amount to be transferred to this recipient.
                                            </li>
                                            <li className="mb-1 mt-2"><strong>Message (string)</strong>: A message associated with this specific transfer.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="response-section"
                    className="mb-8 rounded-md p-4"
                >
                    <h1 className="pb-2 text-lg border-b font-semibold ">Response</h1>
                    <p className="my-2">
                    <strong>Status Codes:</strong>
                    </p>
                    <ul className="list-inside">
                        <li className="mb-1"><strong className={'text-green-600'}>200 OK</strong>: The transfer request
                            was successful.
                        </li>
                        <li className="mb-1"><strong className={'text-red-600'}>400 Bad Request</strong>: The request
                            body is invalid.
                        </li>
                        <li className="mb-1"><strong className={'text-red-600'}>500 Internal Server Error</strong>: An
                            error occurred on the server.
                        </li>
                    </ul>

                    <p className="mt-2">
                        <strong className="">
                            Body:
                        </strong>
                    </p>
                    <div
                        className="relative flex flex-col bg-gray-100 dark:bg-gray-800 overflow-x-auto rounded-md    form-input text-sm">
                        <pre className="overflow-x-auto  rounded-md  bg-opacity-75 p-4  text-sm ">
                            <code>
                                {`{
  "phoneNumber": "string",
  "referenceId": "string",
  "amount": 0,
  "callbackUrl": "string",
  "description": "string",
  "transfers": [
    {
      "phoneNumber": "string",
      "percentage": 0,
      "message": "string"
    }
  ]
}`}
                            </code>
                        </pre>
                        <CopyToClipboard
                            text={`{
  "phoneNumber": "string",
  "referenceId": "string",
  "amount": 0,
  "callbackUrl": "string",
  "description": "string",
  "transfers": [
    {
      "phoneNumber": "string",
      "percentage": 0,
      "message": "string"
    }
  ]
}`}
                        >
                            <button
                                className="absolute top-2 right-2 rounded-md bg-primary px-3 py-1 text-white"
                                onClick={handleCopy}
                            >
                                {copied ? (
                                    <FontAwesomeIcon icon={faCheck} /> // Show checkmark icon when copied
                                ) : (
                                    <FontAwesomeIcon icon={faCopy} /> // Show copy icon by default
                                )}
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div className="w-full mt-2">
                        <div className="flex items-center justify-center mb-5">
                            <div className="border border-white-dark/20 rounded-md w-full p-5">
                                <ul className="space-y-3 list-inside text-md list-disc font-semibold">
                                    <li className="mb-1"><strong>Status (string)</strong>: The status of the transfer
                                        request (either "success" or "error").
                                    </li>
                                    <li className="mb-1"><strong>Message (string)</strong>: A message indicating the
                                        status of the transfer request.
                                    </li>
                                    <li className="mb-1"><strong>Data (number)</strong>: An object containing the data
                                        related to the transfer:
                                    </li>
                                    <li className="inline">
                                        <ul className="list-inside pl-10 space-y-3">
                                            <li className="mb-1 mt-2"><strong>TransferId (string)</strong>: The unique
                                                ID of the transfer.
                                            </li>
                                            <li className="mb-1 mt-2"><strong>TotalAmountTransferred (number)</strong>:
                                                The total amount transferred.
                                            </li>
                                            <li className="mb-1 mt-2"><strong>Transfers (array)</strong>: An array of
                                                transfer objects containing the following properties:
                                            </li>
                                            <li className="inline">
                                                <ul className="list-inside pl-10 space-y-3">
                                                    <li className="mb-1 mt-2"><strong>Recipient (string)</strong>: The
                                                        phone number of the recipient for each transfer.
                                                    </li>
                                                    <li className="mb-1 mt-2"><strong>AmountTransferred
                                                        (number)</strong>: The amount transferred to this recipient.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
