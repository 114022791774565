import React, { Fragment, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useDeleteIpAddressMutation } from '../../../redux.Ip';
import { Dialog, Transition } from '@headlessui/react';
import IconTrash from '@/components/Icon/IconTrash';

export default function DeleteIP({ handleRefetch, data }: any) {
    const [modal1, setModal1] = useState(false);

    const [deleteIpAddress, { isLoading }] = useDeleteIpAddressMutation();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await deleteIpAddress(data.id);
            handleRefetch();
            setModal1(false);
        } catch (error) {
            return error;
        }
    };

    return (
      
            <>
                <div className="flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => setModal1(true)}
                        className="  btn btn-sm btn-primary"
                    >
                        <IconTrash />
                    </button>
                </div>
                <Transition appear show={modal1} as={Fragment}>
                    <Dialog
                        as="div"
                        open={modal1}
                        onClose={() => setModal1(false)}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0" />
                        </Transition.Child>
                        <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                            <div className="flex items-start justify-center min-h-screen px-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        as="div"
                                        className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                    >
                                        <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                            <div className="text-lg flex flex-row justify-center w-full ">
                                                Delete IP Address
                                            </div>
                                        </div>
                                        <div className="p-5 ">
                                            <form onSubmit={handleSubmit}>
                                                <p className=" p-2 text-md text-gray-500 ">
                                                    {' '}
                                                    Are you sure you want to
                                                    delete this IP address{' '}
                                                    <span className="text-red-500 mx-2 ">
                                                        {data?.name}
                                                    </span>
                                                </p>

                                                <div className="mt-2 flex flex-row justify-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary "
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress
                                                                size={24}
                                                                sx={{
                                                                    color: 'black',
                                                                }}
                                                            />
                                                        ) : (
                                                            <span>Delete</span>
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </>
        
    );
}
