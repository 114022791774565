 export default function formatDateToLongForm(dateString:any) {
    const options:any = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }


 export function formatDateToLongFormWithTime(dateString: any) {
     const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
     return new Date(dateString).toLocaleDateString(undefined, options);
 }


