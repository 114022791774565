
import React, { Fragment, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useCreateIpAddressMutation } from "../../../redux.Ip";
import { Dialog, Transition } from "@headlessui/react";

export default function AddIPaddress({ handleRefetch }: any) {
  const [modal1, setModal1] = useState(false);
  const [name, setName] = useState<string>("");
  const [ip, setIp] = useState<any>("");
  const [nameError, setNameError] = useState<string>("");
  const [ipError, setIpError] = useState<string>("");
  const [createApiKey, { isLoading }] = useCreateIpAddressMutation();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name || !ip) {
      if (!name) {
        setNameError("Name is required.");
      } else {
        setNameError(""); 
      }

      if (!ip) {
        setIpError("IP is required.");
      } else {
        setIpError(""); // Clear the error message if IP is provided
      }

      return;
    }

    try {
      const newApiKeyData: any = {
        name: name,
        ip: ip,
      };

      const data: any = await createApiKey(newApiKeyData);

      handleRefetch()
      setModal1(false)
    } catch (error) {
      return error;
    }
  };

  return (
  
        <>
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary "
                >
                    Add IP Address
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Create IP Address
                                        </div>
                                    </div>
                                    <div className="p-5 ">
                                    <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Name of your IP"
                className="form-input "
                onChange={(e) => setName(e.target.value)}
              />
              <span className="text-sm text-red-500">{nameError}</span>
            </div>

            <div className="form-group mt-2 flex flex-col ">
              <input
                type="text"
                placeholder="Your IP"
                className="form-input "
                onChange={(e) => setIp(e.target.value)}
              />
              <span className="text-sm text-red-500">{ipError}</span>
            </div>

            <div className="mt-2">
              <button
                type="submit"
                className="btn btn-primary"
              >
                {isLoading ? (
                  <CircularProgress size={24} sx={{ color: "black" }} />
                ) : (
                  <span>Save</span>
                )}
              </button>
             
            </div>
          </form>
        </div>
                                    
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
      
  
  );
}
