

import baseApi from "@/core/lib/base";
import { statsSlice } from '@/app/dashboard/main/slice';
import { storage } from '@/utils';

export interface StatsModel {
    total: number,
    pending: number,
    success: number,
    failed: number,
}

export interface WeekTransactionModel {
    total: number,
    day: string,
    date: string
}

export interface MonthTransactionModel {
    total: number,
    month: string,
    year: number
}

export interface YearTransactionModel {
    total: number,
    year: number
}

export const statsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStatsTransactions: builder.query<StatsModel, {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/api/v1/transactions/stats",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;

                    dispatch(statsSlice.actions.setTransaction(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),

        getWeeklyTransactions: builder.query<WeekTransactionModel[], {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/api/v1/transactions/reports/this-week-transactions",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(statsSlice.actions.setWeeklyTransactions(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),

        getMonthlyTransactions: builder.query<MonthTransactionModel[], { year: string }>({
            query: (params) => {
                return {
                    method: "GET",
                    url: `/api/v1/transactions/reports/monthly-transactions?year=${params.year}`,
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(statsSlice.actions.setMonthlyTransactions(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),

        getYearlyTransactions: builder.query<YearTransactionModel[], {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/api/v1/transactions/reports/yearly-transactions",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(statsSlice.actions.setYearlyTransactions(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),

        getStatsTransfers: builder.query<StatsModel, {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/api/v1/transfer/stats",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;

                    dispatch(statsSlice.actions.setTransaction(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),

        getWeeklyTransfers: builder.query<WeekTransactionModel[], {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/api/v1/transfer/reports/this-week-transactions",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(statsSlice.actions.setWeeklyTransactions(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),

        getMonthlyTransfers: builder.query<MonthTransactionModel[], { year: string }>({
            query: (params) => {
                return {
                    method: "GET",
                    url: `/api/v1/transfer/reports/monthly-transactions?year=${params.year}`,
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(statsSlice.actions.setMonthlyTransactions(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),

        getYearlyTransfers: builder.query<YearTransactionModel[], {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/api/v1/transfer/reports/yearly-transactions",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(statsSlice.actions.setYearlyTransactions(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login';
                    }
                    throw error;
                }
            },
        }),
    }),
});

export const {
    useGetStatsTransactionsQuery,
    useGetMonthlyTransactionsQuery,
    useGetWeeklyTransactionsQuery,
    useGetYearlyTransactionsQuery,

    useGetStatsTransfersQuery,
    useGetMonthlyTransfersQuery,
    useGetWeeklyTransfersQuery,
    useGetYearlyTransfersQuery,
} = statsApi;
