import { TransactionModel } from './model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	transaction:TransactionModel | null 
}

const initialState: InitialState = {
	transaction: null,
};

export const transactionSlice = createSlice({
	initialState,
	name: 'transactionSlice',
	reducers: {
		setTransaction: (state, action: PayloadAction<TransactionModel>) => {

			state.transaction = action.payload;
		},
		
	},
});
