

import baseApi from "@/core/lib/base";
import { ResponseModel } from "@/core/types";

import { transactionSlice } from "./slice";
import { TransactionModel } from "./model";
import { storage } from '@/utils';

export const transactionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransaction: builder.query<ResponseModel<TransactionModel>, { pageNumber?: number, pageSize?: number, from?: string, to?: string, status?: string ,search?:string}>({
      query: (queryParameters) => {
        const { pageNumber, pageSize, from, to, status,search } = queryParameters;
        return {
          method: "GET",
          url: "/api/v1/transactions",
          params: {
            pageNumber,
            pageSize,
            from,
            to,
            search,
            status,
          },
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;

          dispatch(transactionSlice.actions.setTransaction(data));
        } catch (error: any) {
            if(error.error.status === 401) {
                storage.removeToken();
                window.location.href = '/login';
            }
          throw error;
        }
      },
    }),
  }),
});

export const { useGetTransactionQuery } = transactionApi;
