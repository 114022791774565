import { Link } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import {
    useGetMonthlyTransactionsQuery,
    useGetMonthlyTransfersQuery, useGetStatsTransactionsQuery,
    useGetStatsTransfersQuery,
    useGetWeeklyTransactionsQuery,
    useGetWeeklyTransfersQuery,
    useGetYearlyTransactionsQuery,
    useGetYearlyTransfersQuery,
} from '@/app/dashboard/main/api';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { TimeChartTransaction, TimeChartTransfer } from '@/app/dashboard/main/components';
import { useAppSelector } from '@/store';
import { KeyIcon, PlayCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { Tab } from '@headlessui/react';
import { BiTransfer } from 'react-icons/bi';
import { FaHouseUser } from 'react-icons/fa';


export default function MainDashboard() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Dashboard |'));
    });

    const { user } = useAppSelector((state) => state.userState)

    const now = Date.now();
    const yearString = new Date(now).getFullYear().toString();

    const [yNum, setYNum] = useState(yearString);

    const { isLoading, data } = useGetStatsTransactionsQuery({});
    const month = useGetMonthlyTransfersQuery({ year: yNum });
    const week = useGetWeeklyTransfersQuery({});
    const year = useGetYearlyTransfersQuery({});

    const { isLoading: isLoadingT, data: dataT } = useGetStatsTransfersQuery({});

    const monthT = useGetMonthlyTransactionsQuery({ year: yNum });
    const weekT = useGetWeeklyTransactionsQuery({});
    const yearT = useGetYearlyTransactionsQuery({});

    const tabs = user?.role == 'admin' ? [
        {
            icon: FaHouseUser,
            title: 'Transactions',
            selected: true,
        },
        {
            icon: BiTransfer,
            title: 'Transfers',
            selected: true,
        },
    ] : [
        {
            icon: FaHouseUser,
            title: 'Transactions',
            selected: true,
        },
        {
            icon: BiTransfer,
            title: 'Transfers',
            selected: true,
        },
    ];

    return (
        <div className="p-3">
            <div className="my-2">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
                    <li>
                        <Link
                            to="/dashboard"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>
                </ul>
                { <>
                    <p className={' hover:underline mb-2'}>Transfers</p>
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        {isLoadingT ? [1, 1, 1, 1].map(() => <div
                            className="mb-5 w-full flex items-center justify-center">
                            <div
                                className="max-w-[20rem] h-14 w-full bg-white animate-pulse shadow-md rounded border border-white-light dark:border-0 dark:bg-primary-dark-light dark:shadow-none p-5">

                            </div>
                        </div>) : dataT &&
                            <>
                                <StatCard title={'Total'} value={`${dataT.total}`} />
                                <StatCard title={'Pending'} value={`${dataT.pending}`} background={'bg-yellow-600'} />
                                <StatCard title={'Successful'} value={`${dataT.success}`} background={'bg-green-600'} />
                                <StatCard title={'Failed'} value={`${dataT.failed}`} background={'bg-red-600'} />
                            </>
                        }
                    </div>

                </>}
                <p className={' hover:underline mb-2'}>Transactions</p>
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    {isLoading ? [1, 1, 1, 1].map(() => <div
                        className="mb-5 w-full flex items-center justify-center">
                        <div
                            className="max-w-[20rem] h-14 w-full bg-white animate-pulse shadow-md rounded border border-white-light dark:border-0 dark:bg-primary-dark-light dark:shadow-none p-5">

                        </div>
                    </div>) : data &&
                        <>
                            <StatCard title={'Total'} value={`${data.total}`} />
                            <StatCard title={'Pending'} value={`${data.pending}`} background={'bg-yellow-600'} />
                            <StatCard title={'Successful'} value={`${data.success}`} background={'bg-green-600'} />
                            <StatCard title={'Failed'} value={`${data.failed}`} background={'bg-red-600'} />

                        </>
                    }
                </div>

                <div className="panel p-3">
                    <Tab.Group>
                        <Tab.List
                            className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                            {tabs.map((item, index) => (
                                <Tab key={index} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={`${
                                                selected
                                                    ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#39437b] dark:!border-b-black'
                                                    : 'dark:text-gray-400'
                                            }
                                    dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-primary`}
                                        >
                                            <item.icon className="ltr:mr-2 w-5 rtl:ml-2" />
                                            {item.title}
                                        </button>
                                    )}
                                </Tab>
                            ))}

                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>
                                <TimeChartTransaction
                                    monthData={monthT.data ?? []}
                                    yearData={yearT.data ?? []}
                                    weekData={weekT.data ?? []}
                                    setYear={setYNum}
                                    year={yNum} />
                            </Tab.Panel>
                            <Tab.Panel>
                                <TimeChartTransfer
                                    monthData={month.data ?? []}
                                    yearData={year.data ?? []}
                                    weekData={week.data ?? []}
                                    setYear={setYNum}
                                    year={yNum} />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    )
}

const StatCard = ({ title = '', value = '', background = 'bg-primary' }) => {
    return (
        <div className="mb-5 w-full flex items-center justify-center">
            <div
                className={`max-w-[20rem] w-full ${background}  shadow-[4px_6px_10px_-3px_#bfc9d4] rounded border border-white-light dark:border-0 dark:shadow-none py-3 px-4`}>
                <div className="text-center flex flex-row justify-between font-semibold">
                    <h5 className="text-white text-lg font-bold dark:text-white-light">{title}</h5>
                    <p className="text-white text-lg">{value}</p>
                </div>
            </div>
        </div>
    )
}
