import { IpModel } from "./ipModel";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  ipAddresses: IpModel[] 
}

const initialState: InitialState = {
  ipAddresses: [],
};

export const ipSlice = createSlice({
  initialState,
  name: 'ipSlice',
  reducers: {
    setIpAddresses: (state, action: PayloadAction<IpModel[]>) => {
      state.ipAddresses = action.payload;
    },
  },
});