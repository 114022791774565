
import { DashCard } from '@/components/Dashcard/Index';
import { storage } from '@/utils';
import React from 'react';
import { FaCodeBranch, FaHouseUser, FaLocationArrow, FaProjectDiagram, FaSearchLocation, FaTags, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Widgets() {


  return (
    <>
      <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
        <li>
          <Link to="/dashboard" className="text-primary hover:underline">
            Dashboard
          </Link>
        </li>
      </ul>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-3'>
        <Link to="/dashboard/users">
          <DashCard
            title="Users"
            value={ 0}
            icon={<FaUsers />}
            backgroundColor="#FF9800"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/dashboard/branches">
          <DashCard
            title="Branches"
            value={ 0}
            icon={<FaCodeBranch />}
            backgroundColor="#00BFA5"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/dashboard/offices">
          <DashCard
            title="Offices"
            value={ 0}
            icon={<FaSearchLocation />}
            backgroundColor="#2196F3"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/dashboard/clients">
          <DashCard
            title="Clients"
            value={ 0}
            icon={<FaHouseUser />}
            backgroundColor="#9C27B0"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/dashboard/products">
          <DashCard
            title="Products"
            value={ 0}
            icon={<FaProjectDiagram />}
            backgroundColor="#F44336"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/dashboard/products/categories">
          <DashCard
            title="Categories"
            value={ 0}
            icon={<FaTags />}
            backgroundColor="#4CAF50"
            iconColor="#FFFFFF" />
        </Link>
      </div>
     
    </>
  );
}

export default Widgets;
