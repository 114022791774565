import { Outlet } from "react-router-dom";
import Logo from "@/assets/images/logo_1.png";
import logiSvg from "@/assets/images/Secure login-rafiki.svg";

export const AuthLayout = () => {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 bg-white dark:bg-black">
        <div className="hidden min-h-screen bg-gradient-to-r from-primary to-primary lg:flex lg:items-center lg:justify-center">
          <img alt="authorization" src={logiSvg} className="w-[80%]" />
        </div>
        <main className="bg-white dark:bg-black flex flex-row justify-center items-center">
          <div className="container sm:w-full md:w-[70%] lg:w-full flex min-h-screen flex-col justify-center items-center gap-12 py-14 px-12">
            <div className="flex w-full flex-col items-start justify-start">
              <div className="text-center relative">
                <img src={Logo} alt={'Besoft'} className={'w-52'} />
              </div>
            </div>

            <div className="mt-5 w-full md:w-[85%]">
              <Outlet />
            </div>
            <div></div>
          </div>
        </main>
      </div>
    </>
  );
};
