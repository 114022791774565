import * as z from "zod";
import { Link, useNavigate } from "react-router-dom";


import CircularProgress from "@mui/material/CircularProgress";
import { useSignupMutation } from "../redux";
import { useEffect } from "react";
import { Form } from "@/components/form";
import { InputField } from "@/components/input";

const schema = z
  .object({
    username: z.string().min(1, "Username is required").max(100),
    name: z.string().min(1, "Name is required"),

    password: z
      .string()
      .min(1, "Password is required")
      .min(8, "Password must have more than 8 characters"),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Passwords do not match",
  });

export type SignupInput = z.TypeOf<typeof schema>;

export const Signup = () => {

  const navigate = useNavigate();

  const [onSignup, { isSuccess, isLoading }] = useSignupMutation();



useEffect(() => {
  
  if (isSuccess  ){
    setTimeout(() => {
      navigate('/dashboard');
    }, 2000)
  }
  else{
  
  }

}, [isSuccess, navigate]);
  return (
    <>
      <div>
        <h2 className="text-[32px] font-semibold text-primary/90">
          Create account
        </h2>
      </div>

      <Form<SignupInput, typeof schema>
        schema={schema}
        onSubmit={(payload) => onSignup(payload)}
        className="mt-10"
      >
        {({ register, formState }) => (
          <>
            <div className="space-y-8">
              <div className="space-y-4">
                <InputField
                  type="text"
                  label="name"
                 
                  placeholder="Eg: John"
                  focus
                  loading={isLoading}
                  error={formState.errors.name}
                  registration={register("name")}
                  className="h-13"
                />
                <InputField
                  type="text"
                  label="username"
                  placeholder="Eg: Doe"
              
                  focus
                  loading={isLoading}
                  error={formState.errors.username}
                  registration={register("username")}
                  className="h-13"
                />
              </div>

              <div className="space-y-4">
                <div className="password grid grid-cols-2 gap-4">
                  <InputField
                    type="password"
                    label="Password"
                    loading={isLoading}
                  
                    placeholder="password"
                    error={formState.errors.password}
                    registration={register("password")}
                    className="h-13"
                  />

                  <InputField
                    type="password"
                    label="Confirm Password"
                    loading={isLoading}
                 
                    placeholder="Confirm Password"
                    error={formState.errors.confirmPassword}
                    registration={register("confirmPassword")}
                    className="h-13"
                  />
                </div>
              </div>
            </div>

            {isLoading ? (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
              >
                <CircularProgress size={24} sx={{ color: "white" }} />
              </button>
            ) : (
              <button
                type="submit"
                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
              >
                Register
              </button>
            )}
            <p className="text-md mt-1 text-gray-500 ">
              Already have an account?{" "}
              <Link to="/login" className="font-medium text-primary ">
                Log In
              </Link>
            </p>
          </>
        )}
      </Form>
    </>
  );
};
