import { ApiKeyModel } from "../types.Api/apiModel";
import { apiKeySlice } from "../types.Api/apiSlice";

import toast from "react-hot-toast";
import { ResponseModel } from "@/core/types";
import baseApi from "@/core/lib/base";
import { storage } from '@/utils';


export const apiKeyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getApiKey: builder.query<ResponseModel<ApiKeyModel>, null>({
      query: () => ({
        method: "GET",
        url: "/api/v1/access-keys", // Change the endpoint URL
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;

          dispatch(apiKeySlice.actions.setApiKey(data));
        } catch (error: any) {
            if(error.error.status === 401) {
                storage.removeToken();
                window.location.href = '/login';
            }
          throw error;
        }
      },
    }),
    createApiKey: builder.mutation<ApiKeyModel, Partial<ApiKeyModel>>({
      query: (newApiKey) => ({
        method: "POST",
        url: "/api/v1/access-keys",
        body: newApiKey,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          toast.success('Key Created')
        } catch (error:any) {
            if(error.error.status === 401) {
                storage.removeToken();
                window.location.href = '/login';
            }
          toast.error(error?.error?.data?.message)
          throw error;
        }
      },
    }),
  }),
});

export const { useGetApiKeyQuery, useCreateApiKeyMutation } = apiKeyApi;
