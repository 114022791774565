import { ApiKeyModel } from "./apiModel";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  apiKey: ApiKeyModel | null;
}

const initialState: InitialState = {
  apiKey: null,
};

export const apiKeySlice = createSlice({
  initialState,
  name: 'apiKeySlice',
  reducers: {
    setApiKey: (state, action: PayloadAction<ApiKeyModel>) => {
      state.apiKey = action.payload;
    },
  },
});