import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    MonthTransactionModel,
    StatsModel,
    WeekTransactionModel,
    YearTransactionModel,
} from '@/app/dashboard/main/api';

interface InitialState {
    stats: StatsModel | null,
    weeklyTransactions: WeekTransactionModel[],
    monthlyTransactions: MonthTransactionModel[],
    yearlyTransactions: YearTransactionModel[],
}

const initialState: InitialState = {
    stats: null,
    weeklyTransactions: [],
    monthlyTransactions: [],
    yearlyTransactions: []
};

export const statsSlice = createSlice({
    initialState,
    name: 'statsSlice',
    reducers: {
        setTransaction: (state, action: PayloadAction<StatsModel>) => {
            state.stats = action.payload;
        },
        setWeeklyTransactions: (state, action: PayloadAction<WeekTransactionModel[]>) => {
            state.weeklyTransactions = action.payload;
        },
        setMonthlyTransactions: (state, action: PayloadAction<MonthTransactionModel[]>) => {
            state.monthlyTransactions = action.payload;
        },
        setYearlyTransactions: (state, action: PayloadAction<YearTransactionModel[]>) => {
            state.yearlyTransactions = action.payload;
        },

    },
});
